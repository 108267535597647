<template>
    <div>
        <div class="wz"> 
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>信息资讯</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="wzright" style="float:right; margin-top:-20px;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="gotoAdd">添加信息</el-button>
            </div>
        </div>
        <div class="box" style="margin-top:30px;">
            <div class="list">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="title"
                    label="标题">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="60"
                    label="图片">
                        <template slot-scope="scope">
                            <show-img  :url="scope.row.url" width="300" wid="40px"></show-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="pageviews"
                    align="center"
                    label="点击数">
                    </el-table-column>
                    <el-table-column
                    prop="author"
                    label="发布人">
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    label="发布时间">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="180"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="gotoEdit(scope.row.id)">编辑</el-button>
                            <el-button type="danger"  @click="delArticle(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up title="添加分类" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-input placeholder="分类名" v-model="addfrom.title"></el-input>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from '@/api/information'
import _api from '@/api/index'
export default {
    data(){
        return{
            total:0,
            pageSize:10,
            pageNo:1,
            addshow:false,
            addfrom:{
                title:""
            },
            list:[]
        }
    },
    created(){
        this.pageNo=this.getContextData("currentPageinfo") || 1;
        this.getList()
    },
    methods:{
        setContextData: function(key, value) { 
            if(typeof value == "string"){
                sessionStorage.setItem(key, value);
            }else{
                sessionStorage.setItem(key, JSON.stringify(value));
            }
        },
        // 从sessionStorage取值
        getContextData: function(key){
            const str = sessionStorage.getItem(key);
            if( typeof str == "string" ){
                try{
                    return JSON.parse(str);
                }catch(e) {
                    return str;
                }
            }
            return;
        },
        gotoEdit(id){
            this.$router.push({path:"/information/edit",query:{id:id}})
        },
        getList(){
            _api.post(apiurl.newslist,{pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
                //console.log(res)
                if(res.success){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        delArticle(id){
            _api.get(apiurl.newsdel,{id:id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        openadd(){
            this.addshow=true
        },
        getclose(){
            this.addshow=false
        },
        getconfirm(data){
            this.addshow=false
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.setContextData("currentPageinfo",this.pageNo)
            this.getList()
        },
        gotoAdd(){
            this.$router.push("/information/add")
        }
    }
}
</script>
<style scoped>

</style>